<template>
  <div class="grid" v-if="showDashboard">
    <!-- Total credit -->
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">Total credit</span>
            <div class="mb-2">Balance: $<span class="text-900 font-medium">{{ total_credit? total_credit.balance : 0 }}</span></div>
            <div class="">In Progress: $<span class="text-900 font-medium">{{ total_credit? total_credit.frozen_balance : 0 }}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-yellow-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-credit-card text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Total spend -->
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0 dash-content">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">Total spend</span>
            <div class="mb-2">Amount: #<span class="text-900 font-medium">{{ total_spend? total_spend.cnt : 0 }}</span></div>
            <div class="">Total: $<span class="text-900 font-medium">{{ total_spend? total_spend.sum : 0}}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-dollar text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Total profit -->
    <div class="col-12 lg:col-6 xl:col-3" v-if="isAdmin">
      <div class="card mb-0 dash-content">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">Total profit</span>
            <div class="">Total: $<span class="text-900 font-medium">{{ total_spend ? total_spend.pure_sum : 0 }}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-arrow-up text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Total rejected -->
    <div class="col-12 lg:col-6 xl:col-3" v-if="isAdmin">
      <div class="card mb-0 dash-content">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">Total rejected</span>
            <div class="mb-2">Rejected: #<span class="text-900 font-medium">{{ total_rejected? total_rejected.cnt : 0 }}</span></div>
            <div class="mb-2">Amount: $<span class="text-900 font-medium">{{  total_rejected? total_rejected.sum : 0 }}</span></div>
            <div class="">Profit lost: $<span class="text-900 font-medium">{{ total_rejected? total_rejected.pure_sum : 0 }}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-red-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-ban text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3" v-if="isAdmin">
      <div class="card mb-0 dash-content">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">Total completed</span>
            <div class="mb-2">Current month: <span class="text-900 font-medium">{{ orders_by_months ? orders_by_months.one : 0 }}</span></div>
            <div class="mb-2">3 months: <span class="text-900 font-medium">{{ orders_by_months ? orders_by_months.three : 0 }}</span></div>
            <div class="mb-2">6 months: <span class="text-900 font-medium">{{ orders_by_months ? orders_by_months.six : 0 }}</span></div>
            <div class="">12 months: <span class="text-900 font-medium">{{ orders_by_months ? orders_by_months.twelve : 0 }}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-chart-line text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3" v-if="isAdmin">
      <div class="card mb-0 dash-content" style="height: 100%;">
        <div class="flex justify-content-between dash-content">
          <div>
            <span class="block text-500 font-medium mb-3">RNP</span>
            <div class="mb-2">Previous month profit: $<span class="text-900 font-medium">{{ last_month_profit ? last_month_profit : 0 }}</span></div>
            <div class="mb-2">Previous month orders count: <span class="text-900 font-medium">{{ last_month_orders_count ? last_month_orders_count : 0 }}</span></div>
            <div class="mb-2">This month profit: $<span class="text-900 font-medium">{{ this_month_profit ? this_month_profit : 0 }}</span></div>
          </div>
          <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
            <i class="pi pi-money-bill text-xl text-black-alpha-90"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2" v-if="isAdmin">
    <Chart type="line" :data="chartData" :options="chartOptions" class="h-30rem" />
  </div>
  <div class="mt-2">
    <orders type="dashboard"></orders>
  </div>
</template>

<script>
import dashboardResource from "@http/DashboardResource";
import Orders from "@components/orders/Orders"
import user from '@/models/User';
import store from "@store";

export default {
  components: {Orders},
  data() {
    return {
      showDashboard: false,
      ordersAmount: null,
      chartData: {},
      chartOptions: {},
      total_credit: {
        balance: 0,
        frozen_balance: 0,
      },
      total_spend: {
        cnt: 0,
        sum: 0,
        pure_sum: 0,
      },
      total_rejected: {
        cnt: 0,
        sum: 0,
        pure_sum: 0,
      },
      orders_by_months: {
        one: 0,
        three: 0,
        six: 0,
        twelve: 0,
      },
      last_month_profit: {
        profits: 0,
      },
      this_month_profit: 0,
      last_month_orders_count: 0,
      last_year_orders: {
        months: [],
        orders: [],
      }

    }
  },

  mounted() {
    this.chartOptions = this.setChartOptions();
    this.getTotalBalance();
  },
  computed: {
    isAdmin() {
      return [user.ROLE_SUPER_ADMIN].includes(store.getters['auth/getRole']);
    }
  },
  methods: {
    setChartData(months, profits, ordersAmount) {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: months,
        datasets: [
          {
            label: 'Profit',
            data: profits,
            fill: false,
            borderColor: documentStyle.getPropertyValue('--cyan-500'),
            tension: 0.4
          },
          {
            label: 'Orders Amount',
            data: ordersAmount,
            fill: false,
            borderColor: documentStyle.getPropertyValue('--green-500'),
            tension: 0.4
          }
        ]
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          }
        }
      };
    },
    getTotalBalance() {
      dashboardResource.getData()
        .then(({data}) => {
          const { total_balance, total_spend, total_rejected, one_month, three_months, six_months, twelve_months, last_month_profit, last_year_profits, last_year_orders, last_month_orders_count, this_month_profit } = data;
          this.total_credit = total_balance;
          this.total_spend = total_spend;
          this.total_rejected = total_rejected;
          this.orders_by_months.one = one_month;
          this.orders_by_months.three = three_months;
          this.orders_by_months.six = six_months;
          this.orders_by_months.twelve = twelve_months;
          this.last_month_profit = last_month_profit;
          this.last_year_orders = last_year_orders;
          this.last_month_orders_count = last_month_orders_count;
          this.this_month_profit = this_month_profit;
          this.chartData = this.setChartData(last_year_profits.months, last_year_profits.profits, last_year_orders.orders);
          this.showDashboard = true;
        });
    }
  }
}
</script>

<style>
  .dash-content {
    min-height: 92px;
  }
</style>
